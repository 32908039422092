import Choices from 'choices.js';

export function initChoices() {
  const elements = document.querySelectorAll('.js-choice');

  elements.forEach((element) => {
    // Elemente nur einmal initialisieren
    if (element.dataset.usesChoices) {
      return;
    }

    const choices = new Choices(element, {
      itemSelectText: 'Auswählen',
      searchFields: ['label'],
      shouldSort: false,
      fuseOptions: {
        // exakter Treffer inherhalb 100 Zeichen
        threshold: 0.1,
        distance: 1000
      }
    });
    element.dataset.usesChoices = true;
    element.choices = choices;
  });

  return elements;
}
